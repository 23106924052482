<template>
    <div class="Homefooter">
        <img src="../assets/Bottomline.png">
    </div>
</template>

<script>
    export default{
        name: 'App'
    }
</script>