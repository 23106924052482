<template>
<div class="Homenav">
  <nav class="border-none bg-white px-6 py-5 flex items-center min-w-0 h-14">
    <h1 class="font-semibold text-lg"></h1>
    <span>
      <img src="../assets/logo.png" alt="zugzwang" style="height: 70px; width: 200px;">
    </span>
    <span class="flex-1"></span>
    <span class="mr-2 py-2 px-4">
      <img src="../assets/logoside.png" alt="JODO" style="height: 50px; width: 150px;">
    </span>
  </nav>
</div>
</template>

<script>
    export default{
        name: 'App'
    }
</script>