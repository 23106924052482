<template>
<div class="Benefits" id="benefits">
    <div class="container mx-auto flex px-6 py-8 md:flex-row flex-col items-center">
        <div class="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
            <img class="object-cover object-center rounded" src="../assets/Group.png" style="height: 500px;">
      
        </div>
        <div class="lg:max-w-lg lg:w-full">
            <img class="object-cover object-center rounded" src="../assets/videoicon.png">
        </div>
    </div>
</div>
</template>

<script>
    export default{
        name: 'App'
    }
</script>

<style scoped>
    .Benefits{
        background: url(../assets/childrentransparent.png) no-repeat center center; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
    }
</style>